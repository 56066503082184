import React from "react"
import Layout from "../components/layout";

import BrokerTable from "../components/broker_table";
import BrokerCard from "../components/broker_card";

import SEO from "../components/seo"


import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiPageSideBar,
  EuiCallOut,
  EuiText,
  EuiHorizontalRule,
  EuiFlexItem,
  EuiButton,
  EuiFlexGroup,
  EuiLoadingContent
} from '@elastic/eui';

import { css } from '@linaria/core'

export default ({ pageContext: broker }) => {

  const brokersMore = broker.subs.map((element) => {
      return <BrokerCard key={element.slug} data={element} />
  })  

  const registerButton = css`
    color: white;
  `;
  console.log(broker.tds_slug);
  return <Layout>

    <SEO 
      title={`${broker.name}: подробный обзор брокера`} 
      description={`${broker.name}: регуляторы, торговые платформы, уровень спреда, кредитное плечо и подробный обзор`}
      canonical={`/${broker.slug}/`}
    />

   <EuiPage restrictWidth={1230}>
    <EuiPageSideBar style={{ minWidth: 270 }}>
    <EuiCallOut color={broker.closed ? "danger" : "success"} style={{ backgroundColor: '#f6f9f9' }} className={`sticky`}
      title={broker.closed ? "К сожалению, брокер закрыт" : "Перейти на сайт брокера"}
      iconType={broker.closed ? 'crossInACircleFilled' : 'search'}>
      <EuiFlexGroup  direction="column" alignItems="center">
        { !broker.logo.fluid.src ? <EuiLoadingContent lines={3} /> : '' }
        <EuiFlexItem style={{ margin: 0 }} grow={false}>
          <img style={{ width: 136 }} src={broker.logo.fluid.src} alt={`Логотип брокера ${broker.name}`}/>
        </EuiFlexItem>
        {!broker.closed && <EuiFlexItem style={{ margin: 0 }} grow={false}>
          <EuiButton color="secondary" fill>
            <a className={registerButton} href={broker.tds_slug ? `https://register-now.ru/${broker.tds_slug}` : broker.url} rel="nofollow noreferrer" target="_blank">Регистрация</a>
          </EuiButton>
        </EuiFlexItem>}
      </EuiFlexGroup>

    </EuiCallOut>
    </EuiPageSideBar>
    
    <EuiPageBody>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle size="l">
            <h1>Обзор брокера {broker.name}</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPageContent>
        <EuiPageContentBody>
          <BrokerTable broker={broker} />
        </EuiPageContentBody>
      </EuiPageContent>

      <EuiPageContent style={{ marginTop: 20 }}>
      <EuiPageContentBody className={`review`}>
          <EuiText size="xs"><h2>О компании</h2></EuiText>
          <EuiHorizontalRule margin="s" />
          <EuiText size="s" dangerouslySetInnerHTML={{__html: broker.text_company.text_company}} />
          <EuiHorizontalRule margin="s" />
          <EuiText size="xs"><h2>Доступность</h2></EuiText>
          <EuiHorizontalRule margin="s" />
          <EuiText size="s" dangerouslySetInnerHTML={{__html: broker.text_availability.text_availability}} />
          <EuiHorizontalRule margin="s" />
          <EuiText size="xs"><h2>Ключевые возможности</h2></EuiText>
          <EuiHorizontalRule margin="s" />
          <EuiText size="s" dangerouslySetInnerHTML={{__html: broker.text_features.text_features}} />
          <EuiHorizontalRule margin="s" />
          <EuiText size="xs"><h2>Торговые инструменты</h2></EuiText>
          <EuiHorizontalRule margin="s" />
          <EuiText size="s" dangerouslySetInnerHTML={{__html: broker.text_instruments.text_instruments}} />
          <EuiHorizontalRule margin="s" />
          <EuiText size="xs"><h2>Сводный обзор {broker.name}</h2></EuiText>
          <EuiHorizontalRule margin="s" />
          <EuiText size="s" dangerouslySetInnerHTML={{__html: broker.text_review.text_review}} />
      </EuiPageContentBody>
      </EuiPageContent>

      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle size="m" style={{ marginTop: 20 }}>
            <h2>Ещё брокеры:</h2>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPageContent>
        <EuiPageContentBody>
          {brokersMore}
        </EuiPageContentBody>
      </EuiPageContent>

    </EuiPageBody>
  </EuiPage>
   
  </Layout>
}