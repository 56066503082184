import React from 'react';

import {
  EuiBasicTable,
  EuiFlexItem,
  EuiFlexGroup,
  EuiFlexGrid
} from '@elastic/eui';


const BrokerTable = (props) => {

  const columns = [
    {
      field: 'label',
      name: '',
      'data-test-subj': 'labelCell',
      render: (item) => (<span style={{fontWeight: "bold"}}>{item}</span>),
      width: '30%',
      mobileOptions: {
        render: (item) => {

        return <span>
          {item.label}
          </span>
        },
        header: false,
        truncateText: false,
        enlarge: true,
        fullWidth: true,
      },
    },
    {
      field: 'value',
      name: '',
      truncateText: false,
      mobileOptions: {
        render: (item) => {
          if(!props.broker[item.value]) return '—';

          if(item.value === 'countries') {
            return props.broker[item.value].map(elem => {
              return <img style={{ width: 20, marginRight: 2 }} key={`${elem.name_ru}_flag`} src={elem.flag.fluid.src} alt={`Флаг страны ${elem.name_ru}`} />
            })
          }

          if(item.value === 'funding_methods') {
            return props.broker[item.value].map(elem => {
              return <img style={{ width: 20, marginRight: 2 }} key={`${elem.name_ru}_flag`} src={elem.logo.fluid.src} alt={`Логотип ${elem.name_ru}`} />
            })
          }
        }
      },
      render: item => {
        if(!props.broker[item]) return '—';

        if(item === 'countries') {
          const elems = props.broker[item].map(elem => {
              return <EuiFlexItem style={{ flexBasis: "auto" }} key={elem.id}>
                      <EuiFlexGroup style={{ flexBasis: "auto" }} alignItems="center" gutterSize="xs">
                        <EuiFlexItem key={`${elem.name_ru}_flag`} style={{ flexBasis: "auto" }}><img style={{ width: 20 }} src={elem.flag.fluid.src} alt={`Флаг страны ${elem.name_ru}`} /></EuiFlexItem>
                        <EuiFlexItem key={`${elem.name_ru}_name`} style={{ flexBasis: "auto" }}>{elem.name_ru}</EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>        
          })
          return <EuiFlexItem><EuiFlexGrid gutterSize="s">{elems}</EuiFlexGrid></EuiFlexItem>

        } else if(item === 'funding_methods') {
          const elems = props.broker[item].map(elem => {
              return <EuiFlexItem style={{ flexBasis: "auto" }} key={elem.id}>
                      <EuiFlexGroup style={{ flexBasis: "auto" }} alignItems="center" gutterSize="xs">
                        <EuiFlexItem style={{ flexBasis: "auto" }}><img style={{ height: 20 }} src={elem.logo.fluid.src} alt={`Логотип ${elem.name_ru}`} /></EuiFlexItem>
                        <EuiFlexItem style={{ flexBasis: "auto" }}>{elem.name_ru ? elem.name_ru : elem.name}</EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>        
          })
          return <EuiFlexItem><EuiFlexGrid gutterSize="s">{elems}</EuiFlexGrid></EuiFlexItem>

        } else if(['regulators', 'offices', 'broker_types', 'platforms', 'instruments'].includes(item)) {
          
          const elems = props.broker[item].map(elem => {
            return (item === 'instruments') ? elem.name_ru : elem.name
          })
          return elems.join(', ');
        } else if(item === 'max_leverage') {
          return `до 1:${props.broker[item]}`
        } else if(item === 'min_deposit') {
          return `от $${props.broker[item]}`
        } else if(item === 'spreads') {
          return props.broker[item].name_ru;
        }

        return props.broker[item];
      }
    }
  ];


  const items = [{
      id: '1',
      label: 'Год основания', 
      value: 'established'
    },
    {
      id: '2',
      label: 'Страна регистрации',
      value: 'countries'
    },
    {
      id: '3',
      label: 'Регуляторы',
      value: 'regulators'
    },
    {
      id: '4',
      label: 'Офисы',
      value: 'offices'
    },
    {
      id: '5',
      label: 'Тип брокера',
      value: 'broker_types'
    },
    {
      id: '6',
      label: 'Кредитное плечо',
      value: 'max_leverage'
    },
    {
      id: '7',
      label: 'Минимальный депозит',
      value: 'min_deposit'
    },
    {
      id: '8',
      label: 'Размер спреда',
      value: 'spreads'
    },
    {
      id: '9',
      label: 'Торговые платформы',
      value: 'platforms'
    },
    {
      id: '10',
      label: 'Торговые инструменты',
      value: 'instruments'
    },
    {
      id: '11',
      label: 'Методы пополнения счета',
      value: 'funding_methods'
    }
  ];

   const getRowProps = (item) => {
    const { id } = item;
    return {
      'data-test-subj': `row-${id}`,
      className: 'customRowClass',
      onClick: () => console.log(`Clicked row ${id}`),
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: 'customCellClass',
      'data-test-subj': `cell-${id}-${field}`,
      textOnly: true,
    };
  }; 

  return (
    <EuiBasicTable
      items={items}
      rowHeader="label"
      /* compressed={true} */
      columns={columns}
      rowProps={getRowProps}
      cellProps={getCellProps} 
    />
  );
};

export default BrokerTable;